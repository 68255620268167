const api = {
  list: '/v1/econtract/notification',
};

const state = () => ({ data: [] });
const getters = {};
const mutations = {
  setData(state, data) {
    state.data = data;
  },
};
const actions = {
  async getList({ dispatch, commit }, params = {}) {
    const response = await dispatch('get', { url: api.list, params }, { root: true });
    commit('setData', response.data);
    return response;
  },
};

export default { namespaced: true, state, getters, mutations, actions };
