<template>
  <v-list nav dense color="transparent">
    <v-list-item-group color="primary" v-model="nav">
      <template v-for="(item, index) in items">
        <v-list-item
          v-if="!item.children"
          link
          :to="item.to"
          @click="$emit('click:item')"
          :key="`menu-${index}`"
        >
          <v-list-item-icon class="mr-4">
            <component :is="item.icon" :active="isActive(item)" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span v-text="item.text" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-else>
          <v-list-group :key="`menu-${index}`" color="primary" :value="isActive(item)" no-action>
            <template slot="activator">
              <v-list-item-icon class="mr-4">
                <component :is="item.icon" :active="isActive(item)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-text="item.text" />
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group>
              <v-list-item
                class="pl-8"
                v-for="(child, childIndex) in item.children"
                :key="`menu-${index}-${childIndex}`"
                :to="child.to"
                @click="$emit('click:item')"
                link
              >
                <v-list-item-icon class="mr-4">
                  <component :is="child.icon" :active="isActive(child)" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-text="child.text" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>
        </template>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data: () => ({
    nav: 0,
  }),
  mounted() {
    this.nav = this.items.findIndex((v) => {
      if (v.children) {
        return v.children.findIndex((w) => this.$route.path.includes(w.to)) != -1;
      } else {
        return v.to == this.$route.path;
      }
    });
  },
  methods: {
    isActive(item) {
      return (
        this.$route.path == item.to ||
        (this.nav != -1 && this.nav == this.items.findIndex((v) => v == item))
      );
    },
    clickItem() {
      console.log('item, clicked');
    },
  },
};
</script>

<style></style>
