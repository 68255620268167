import iDocumentActive from './i-document-active.vue';
import iDocumentInActive from './i-document-in-active.vue';
import iDocumentIn from './i-document-in.vue';
import iDocumentOutActive from './i-document-out-active.vue';
import iDocumentOut from './i-document-out.vue';
import iDocumentUploadActive from './i-document-upload-active.vue';
import iDocumentUpload from './i-document-upload.vue';
import iDocument from './i-document.vue';
import iDraftActive from './i-draft-active.vue';
import iDraft from './i-draft.vue';
import iFileActive from './i-file-active.vue';
import iFileDocumentIn from './i-file-document-in.vue';
import iHelpActive from './i-help-active.vue';
import iHelp from './i-help.vue';
import iHideSidebarActive from './i-hide-sidebar-active.vue';
import iHideSidebar from './i-hide-sidebar.vue';
import iLogoutActive from './i-logout-active.vue';
import iLogout from './i-logout.vue';
import iSettingActive from './i-setting-active.vue';
import iSetting from './i-setting.vue';
import MICallCalling from './m-i-call-calling.vue';
import MIDirectboxReceive from './m-i-directbox-receive.vue';
import MIEdit from './m-i-edit.vue';
import MIFolder from './m-i-folder.vue';
import MILogout from './m-i-logout.vue';
import MIPenAdd from './m-i-pen-add.vue';
import MITrash from './m-i-trash.vue';
import MIDirectboxSend from './m-i-directbox-send.vue';
import MIDocumentUpload from './m-i-document-upload.vue';
import MINotification from './m-i-notification.vue';
import MISecurityUser from './m-i-security-user.vue';
import MIBuildings from './m-i-buildings-2.vue';
import MIDocumentText from './m-i-document-text-1.vue';

const icons = {
  iDocumentActive,
  iDocumentInActive,
  iDocumentIn,
  iDocumentOutActive,
  iDocumentOut,
  iDocumentUploadActive,
  iDocumentUpload,
  iDocument,
  iDraftActive,
  iDraft,
  iFileActive,
  iFileDocumentIn,
  iHelpActive,
  iHelp,
  iHideSidebarActive,
  iHideSidebar,
  iLogoutActive,
  iLogout,
  iSettingActive,
  iSetting,
};

function componentToIcon(icons) {
  return Object.keys(icons).reduce((carry, key) => {
    const iconsTemp = carry;
    iconsTemp[`${key}`] = { component: icons[key] };
    return iconsTemp;
  }, {});
}

export {
  MICallCalling,
  MIDirectboxReceive,
  MIEdit,
  MIFolder,
  MILogout,
  MIPenAdd,
  MITrash,
  MIDocumentUpload,
  MIDirectboxSend,
  MINotification,
  MISecurityUser,
  MIBuildings,
  MIDocumentText,
};

export default componentToIcon(icons);
