<template>
  <v-snackbar v-model="model" :color="color" top text outlined>
    <div class="d-flex align-center">
      <v-icon size="20" left :color="color" v-text="`$${color}`" />
      <div>
        <div class="font-weight-bold text-body-1" v-text="title" />
        <div v-if="subtitle" class="mt-1" v-html="subtitle" />
      </div>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn x-small :color="color" icon v-bind="attrs" @click="model = false">
        <v-icon>$close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    model: null,
  }),
  computed: {
    ...mapState('alertStore', {
      state: (v) => v.state,
      data: (v) => v.data,
    }),
    color() {
      return this.data.color;
    },
    title() {
      return this.data.title;
    },
    subtitle() {
      return this.data.subtitle;
    },
  },
  watch: {
    state(val) {
      this.model = val;
    },
    model(val) {
      if (!val) {
        this.$store.commit('alertStore/hideAlert');
      }
    },
  },
};
</script>
