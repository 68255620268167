const state = () => ({ state: false, data: {} });
const getters = {};
const mutations = {
  showAlert(state, { color, title, subtitle }) {
    state.state = false;
    state.data = { color, title, subtitle };
    state.state = true;
  },
  hideAlert(state) {
    state.state = false;
    setTimeout(() => {
      state.data = {};
    }, 500);
  },
};
const actions = {
  show(context, { color, title, subtitle }) {
    context.commit('showAlert', { color, title, subtitle });
  },
  showSuccess(context, { title = '', subtitle = '' }) {
    context.dispatch('show', { color: 'success', title, subtitle });
  },
};

export default { namespaced: true, state, getters, mutations, actions };
