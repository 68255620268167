import view from '../views';

const api = [];
const web = [
  {
    path: '/status',
    name: 'status',
    component: view('private/status/private-status'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: view('private/status/private-kyc'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/kyc-start',
    name: 'kyc-start',
    component: view('private/status/private-kyc-start'),
    meta: {
      layout: 'private',
    },
  },
];

export { api };

export default web;
