import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import * as lib from 'vuetify/lib';
import { VTextField, VBtn } from 'vuetify/lib';
import id from 'vuetify/lib/locale/id';

import menuIcons from '../components/icons';

VTextField.options.props.outlined = { type: Boolean, default: true };
VTextField.options.props.hideDetails = { type: [Boolean, String], default: 'auto' };
VBtn.extendOptions.props.elevation = {
  type: [String, Number],
  default: 0,
};

if (Vue.config.devtools) {
  console.log(lib);
}

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { id },
    current: 'id',
  },
  icons: {
    values: menuIcons,
  },
  theme: {
    themes: {
      light: {
        light: '#94A3B8',
      },
    },
  },
});
