import view from '../views';

const api = [];
const web = [
  {
    path: '/dokumen',
    name: 'dokumen',
    component: view('private/dokumen/private-dokumen'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/dokumen/:id',
    name: 'dokumen-detail',
    component: view('private/dokumen/private-dokumen'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/dokumen-masuk',
    name: 'dokumen-masuk',
    component: view('private/dokumen/private-dokumen-masuk'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/dokumen-masuk/:id',
    name: 'dokumen-masuk-detail',
    component: view('private/dokumen/private-dokumen'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/dokumen-keluar',
    name: 'dokumen-keluar',
    component: view('private/dokumen/private-dokumen-keluar'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/dokumen-keluar/:id',
    name: 'dokumen-keluar-detail',
    component: view('private/dokumen/private-dokumen'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/dokumen-draft',
    name: 'dokumen-draft',
    component: view('private/dokumen/private-dokumen-draft'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/sampah',
    name: 'sampah',
    component: view('private/dokumen/private-dokumen-sampah'),
    meta: {
      layout: 'private',
    },
  },
  {
    path: '/guest/:code',
    name: 'guest',
    component: view('dokumen/dokumen-guest'),
  },
];

export { api };

export default web;
