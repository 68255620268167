import view from '../views';

const api = [];
const web = [
  {
    path: '/site-not-found',
    name: 'site-not-found',
    component: view('public/site-not-found'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/',
    redirect: '/dokumen',
  },
];

export { api };

export default web;
