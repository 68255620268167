<template>
  <v-app>
    <v-main>
      <v-row v-if="loading" class="fill-height" align="center" justify="center">
        <v-progress-circular
          class="d-block my-16"
          size="64"
          width="4"
          indeterminate
          color="primary"
        />
      </v-row>
      <template v-else>
        <momofin-public-layout v-if="$route.meta.layout == 'public'" />
        <momofin-private-layout v-else-if="$route.meta.layout == 'private'" />
        <router-view v-else />
      </template>
    </v-main>
    <m-alert />
  </v-app>
</template>

<script>
import MAlert from './components/global/m-alert.vue';
import momofinPrivateLayout from './components/layouts/momofin-private-layout.vue';
import MomofinPublicLayout from './components/layouts/momofin-public-layout.vue';

export default {
  components: { momofinPrivateLayout, MomofinPublicLayout, MAlert },
  data: () => ({
    loading: null,
    intervalCheckReset: null,
  }),
  mounted() {
    if (process.env.VUE_APP_COMPANY_NAME) document.title = process.env.VUE_APP_COMPANY_NAME;
    if (process.env.VUE_APP_COMPANY_FAVICON) {
      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = process.env.VUE_APP_COMPANY_FAVICON;
    }

    this.checkResetPassword();
    this.intervalCheckReset = setInterval(() => {
      this.checkResetPassword();
    }, 1000 * 60);
  },
  beforeDestroy() {
    clearInterval(this.intervalCheckReset);
    this.intervalCheckReset = null;
  },
  methods: {
    checkResetPassword() {
      if (this.$store.getters['userStore/isLoggedIn']) {
        this.$store.dispatch('userStore/checkReset').then((res) => {
          if (res.data.is_reset) {
            clearInterval(this.intervalCheckReset);
            this.intervalCheckReset = null;
            this.$router.push('/set-new-password');
          }
        });
      }
    },
  },
};
</script>
