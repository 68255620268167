import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import en from './en';
import id from './id';

const messages = { en, id };

const i18n = new VueI18n({
  locale: 'id',
  messages,
});

export default i18n;
