import { render, staticRenderFns } from "./m-i-document-text-1.vue?vue&type=template&id=4dd85799&"
import script from "./m-i-document-text-1.vue?vue&type=script&lang=js&"
export * from "./m-i-document-text-1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports