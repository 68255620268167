import { api } from '@/router/user-route';
import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import userStore from './user-store';
import alertStore from './alert-store';
import documentStore from './document-store';
import notificationStore from './notification-store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: { loading: false, auth: {}, domainExists: undefined, company: {} },
  getters: {
    isLoading: (state) => state.loading,
    isLoggedIn: (state) => state.auth.token,
    isDomainExists: (state) => state.domainExists,
    logo: (state) => state.company?.logo,
  },
  mutations: {
    loadingStart(state) {
      state.loading = true;
    },
    loadingStop(state) {
      state.loading = false;
    },
    setCompany(state, data) {
      state.company = data;
    },
    domainExists(state) {
      state.domainExists = true;
    },
    domainNotExists(state) {
      state.domainExists = false;
    },
  },
  actions: {
    async checkDomain({ dispatch, commit }) {
      const res = await dispatch('get', { url: api.check_domain });

      if (res?.code == 200) {
        commit('setCompany', res.data);
        commit('domainExists');
      } else {
        commit('userStore/RESET_AUTH', null, { root: true });
        commit('domainNotExists');
      }
    },
    async initApp({ dispatch, commit }) {
      try {
        const auth = JSON.parse(localStorage.getItem('SET_AUTH')) || {};
        axios.defaults.headers.common['x-access-token'] = auth.token;
        const response = await dispatch('get', { url: api.check_token });

        if (response.code != 200) {
          throw new Error(response.msg);
        }

        const { data } = await dispatch('userStore/profile', {}, { root: true });
        auth.profile = data.profile || {};

        commit('userStore/SET_AUTH', auth, { root: true });
        await dispatch('userStore/statusKyc', {}, { root: true });
        return response;
      } catch (error) {
        commit('userStore/RESET_AUTH', null, { root: true });
        console.error(error);
      }
    },
    async get({ dispatch }, { url, params = {} }) {
      return await dispatch('request', { url, params, method: 'get' });
    },
    async post({ dispatch }, { url, data }) {
      return await dispatch('request', { url, data, method: 'post' });
    },
    async request(context, payload) {
      return new Promise((resolve) => {
        context.commit('loadingStart');
        try {
          axios({ ...payload })
            .then((res) => res.data)
            .then((res) => {
              if (res?.code != 200) {
                throw new Error(res.msg);
              }
              context.commit('loadingStop');
              resolve(res);
            })
            .catch((error) => {
              context.dispatch('error', error);
              context.commit('loadingStop');
              resolve(false);
            });
        } catch (error) {
          context.dispatch('error', error);
          context.commit('loadingStop');
          resolve(false);
        }
      });
    },
    error(context, error) {
      if (Vue.config.devtools) {
        console.error(error);
      }
      let title = error.message;
      let subtitle = '';
      const response = error.response;
      if (
        response?.headers &&
        response?.headers['content-type'].toLowerCase().includes('application/json')
      ) {
        const data = error.response?.data || {};
        title = data.msg || data.message;
      }
      context.dispatch('alertStore/show', { color: 'error', title, subtitle }, { root: true });
    },
  },
  modules: { userStore, alertStore, documentStore, notificationStore },
});
