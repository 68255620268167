var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:!_vm.collapse || 'collapse',attrs:{"no-gutters":"","id":"layout"}},[(_vm.collapse)?_c('v-navigation-drawer',{staticClass:"mt-14",style:({ marginTop: 0 }),attrs:{"color":"white","fixed":"","floating":"","temporary":""},model:{value:(_vm.menuDrawer),callback:function ($$v) {_vm.menuDrawer=$$v},expression:"menuDrawer"}},[_c('m-sidenav',{attrs:{"items":_vm.menus},on:{"click:item":_vm.reloadView}})],1):_vm._e(),_c('m-notification',{attrs:{"loading":_vm.loading},model:{value:(_vm.notificationDrawer),callback:function ($$v) {_vm.notificationDrawer=$$v},expression:"notificationDrawer"}}),(!_vm.collapse)?_c('v-col',{staticClass:"white",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"mb-6",attrs:{"id":"nav-icon"}},[_c('router-link',{attrs:{"to":"/"}},[(_vm.logo)?_c('div',{staticClass:"text-right"},[_c('v-img',{staticClass:"mx-8",attrs:{"src":_vm.logo,"height":"64","contain":""}}),_c('v-img',{staticClass:"ml-auto mr-6",staticStyle:{"margin-top":"-0.5rem"},attrs:{"src":require('@/assets/logo-powered-by.svg'),"width":"66.667%"}})],1):_c('v-img',{attrs:{"src":require("@/assets/logo.png"),"width":"66.667%"}})],1)],1),_c('m-sidenav',{attrs:{"items":_vm.menus},on:{"click:item":_vm.reloadView}}),_c('v-divider'),_c('v-spacer'),_c('v-list',{attrs:{"nav":"","dense":"","color":"transparent"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"link":"","href":"https://momofin.com/about","target":"_blank"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('MIBuildings')],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Tentang ")])],1)],1),_c('v-list-item',{attrs:{"link":"","href":"https://momofin.com/terms-conditions","target":"_blank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-4"},[_c('MIDocumentText',{attrs:{"active":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Syarat & Ketentuan ")])],1)]}}],null,false,1402345335)}),_c('v-list-item',{attrs:{"link":"","href":"https://momofin.com/privacy-policy","target":"_blank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"mr-4"},[_c('MISecurityUser',{attrs:{"active":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Kebijakan Privasi ")])],1)]}}],null,false,4117309563)}),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.handleLogout}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('m-i-logout')],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Logout ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"id":"content"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"white",attrs:{"cols":"12","id":"topnav"}},[_c('v-app-bar',{attrs:{"flat":"","color":"transparent"}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","align":"center"}},[(_vm.collapse)?_c('div',{staticClass:"mr-4"},[_c('v-btn',{attrs:{"icon":"","small":"","color":"light"},on:{"click":function($event){_vm.menuDrawer = !_vm.menuDrawer}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMenu))])],1)],1):_vm._e(),_c('v-col',{attrs:{"id":"nav-content"}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticStyle:{"max-width":"256px"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"dense":"","outlined":"","hide-details":"","placeholder":_vm.$t('search')}})],1),_c('v-spacer'),(true)?_c('div',[(false)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"height":"32","width":"32","icon":"","color":"light"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiLightbulbOutline))])],1)]}}],null,false,1359429214)},[_c('v-list',[_c('v-subheader',[_vm._v("content bulb")])],1)],1):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"height":"32","width":"32","icon":"","color":"light"},on:{"click":_vm.handleShowNotification}},[_c('MINotification')],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"icon":_vm.collapse,"rounded":"","elevation":"0","color":"transparent"}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":require("@/assets/avatar.png")}})],1),(!_vm.collapse)?_c('div',{staticClass:"mx-2 light--text text-capitalize"},[_vm._v(" "+_vm._s((_vm.profile.fullname || '').split(' ')[0])+" ")]):_vm._e(),(!_vm.collapse)?_c('v-icon',{attrs:{"color":"light"}},[_vm._v(_vm._s(_vm.icons.mdiMenuDown))]):_vm._e()],1)]}}],null,false,270445744)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/profil"}},[_c('v-list-item-title',[_vm._v("Profil & KYC")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.handleLogout()}}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-4",attrs:{"cols":"12","id":"content-wrapper"}},[(_vm.view)?_c('router-view',{key:_vm.$route.fullPath}):_vm._e()],1)],1)],1),_c('m-alert')],1)}
var staticRenderFns = []

export { render, staticRenderFns }