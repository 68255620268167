import views from '@/views';

const api = {};
const web = [
  {
    path: '/register',
    name: 'register',
    component: views('public/auth/auth-register'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/registered',
    name: 'registered',
    component: views('public/auth/auth-registered'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: views('public/auth/auth-login'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/auto-login/:code',
    name: 'auto-login',
    component: views('public/auth/auth-auto-login'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: views('public/auth/forgot-password'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/forgot-password/:token',
    name: 'reset-password',
    component: views('public/auth/reset-password'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/set-new-password',
    name: 'set-new-password',
    component: views('set-new-password'),
  },
  {
    path: '/profil',
    name: 'profil',
    component: views('private/auth-profile'),
    meta: {
      layout: 'private',
    },
  },
];

export { api };

export default web;
