export default {
  home: 'Home',
  about: 'Tentang',
  inventory: 'Inventori',
  report: 'Laporan',
  user_management: 'Manajemen Pengguna',
  api_setting: 'Pengaturan API',
  setting: 'Pengaturan',
  documentation: 'Dokumentasi',
};
