import Vue from 'vue';
import VueRouter from 'vue-router';
import PublicRoute from './public-route';
import AuthRoute from './auth-route';
import UserRoute from './user-route';
import DocumentRoute from './document-route';
import KycRoute from './kyc-route';
import store from '@/store';
import view from '@/views';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/not-found',
  },
  {
    path: '/not-found',
    name: '404',
    component: view('public/page-not-found'),
  },
  ...PublicRoute,
  ...AuthRoute,
  ...UserRoute,
  ...DocumentRoute,
  ...KycRoute,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (Vue.config.devtools) {
    // localStorage.setItem(
    //   'SET_AUTH',
    //   JSON.stringify({
    //     token:
    //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZlYWUxZmEwLWY4NTAtMTFlYy05MjZlLWQ5MjA3YzYwMjIzNyIsImJ1c2luZXNzX2lkIjoiNmU1ZDY3OTAtZjg1MC0xMWVjLTkyNmUtZDkyMDdjNjAyMjM3Iiwicm9sZXMiOlsiUk9MRV9TVVBFUkFETUlOIl0sImlhdCI6MTY1ODMwMTM1MywiZXhwIjoxNjY1NTAxMzUzfQ.KN3eJI8-bBHRDKpOI3aegF4HvqTL908Xn58fVC0vnPs',
    //   })
    // );
  } else {
    if (store.getters['isDomainExists'] == undefined) {
      await store.dispatch('checkDomain');
    }

    if (!store.getters['isDomainExists'] && to.path != '/site-not-found') {
      return next('/site-not-found');
    }

    if (store.getters['isDomainExists'] && to.path == '/site-not-found') {
      return next('/');
    }
  }

  if (!!localStorage.SET_AUTH == true && !store.getters['userStore/isLoggedIn']) {
    await store.dispatch('initApp');
  }

  if (store.getters['userStore/isLoggedIn']) {
    switch (to.meta.layout) {
      case 'public':
        return next('/dokumen');
      default:
        return next();
    }
  } else {
    switch (to.meta.layout) {
      case 'private':
        return next('/login');
      default:
        return next();
    }
  }
});

export default router;
