<template>
  <v-row no-gutters id="layout" :class="!collapse || 'collapse'">
    <v-navigation-drawer
      color="white"
      v-if="collapse"
      class="mt-14"
      fixed
      floating
      v-model="menuDrawer"
      temporary
      :style="{ marginTop: 0 }"
    >
      <m-sidenav :items="menus" @click:item="reloadView" />
    </v-navigation-drawer>

    <m-notification v-model="notificationDrawer" :loading="loading" />

    <v-col v-if="!collapse" id="sidebar" class="white">
      <div id="nav-icon" class="mb-6">
        <router-link to="/">
          <div v-if="logo" class="text-right">
            <v-img class="mx-8" :src="logo" height="64" contain />
            <v-img
              class="ml-auto mr-6"
              :src="require('@/assets/logo-powered-by.svg')"
              width="66.667%"
              style="margin-top: -0.5rem"
            />
          </div>
          <v-img v-else src="@/assets/logo.png" width="66.667%" />
        </router-link>
      </div>
      <m-sidenav :items="menus" @click:item="reloadView" />
      <v-divider />
      <v-spacer />
      <v-list nav dense color="transparent">
        <v-list-item-group color="primary">
          <v-list-item link href="https://momofin.com/about" target="_blank">
            <v-list-item-icon class="mr-4">
              <MIBuildings />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Tentang </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link href="https://momofin.com/terms-conditions" target="_blank">
            <template slot-scope="{ active }">
              <v-list-item-icon class="mr-4">
                <MIDocumentText :active="active" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Syarat &amp; Ketentuan </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-list-item link href="https://momofin.com/privacy-policy" target="_blank">
            <template slot-scope="{ active }">
              <v-list-item-icon class="mr-4">
                <MISecurityUser :active="active" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Kebijakan Privasi </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-divider />
          <v-list-item @click="handleLogout">
            <v-list-item-icon class="mr-4">
              <m-i-logout />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>

    <v-col class="pa-0" id="content">
      <v-row no-gutters>
        <v-col cols="12" id="topnav" class="white">
          <v-app-bar flat color="transparent">
            <v-row no-gutters class="flex-nowrap" align="center">
              <div v-if="collapse" class="mr-4">
                <v-btn icon small color="light" @click="menuDrawer = !menuDrawer">
                  <v-icon>{{ icons.mdiMenu }}</v-icon>
                </v-btn>
              </div>
              <v-col id="nav-content">
                <v-row align="center" no-gutters>
                  <v-col v-show="false" style="max-width: 256px">
                    <v-text-field
                      :prepend-inner-icon="icons.mdiMagnify"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('search')"
                    />
                  </v-col>
                  <v-spacer />
                  <div v-if="true">
                    <!-- lightbulb -->
                    <v-menu v-if="false" offset-y>
                      <template slot="activator" slot-scope="{ on, attrs }">
                        <v-btn
                          class="mr-4"
                          height="32"
                          width="32"
                          icon
                          color="light"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiLightbulbOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-subheader>content bulb</v-subheader>
                      </v-list>
                    </v-menu>
                    <!-- bell -->
                    <v-btn
                      class="mr-4"
                      height="32"
                      width="32"
                      icon
                      color="light"
                      @click="handleShowNotification"
                    >
                      <MINotification />
                    </v-btn>
                    <!-- avatar -->
                    <v-menu offset-y>
                      <template slot="activator" slot-scope="{ on, attrs }">
                        <v-btn
                          :icon="collapse"
                          rounded
                          elevation="0"
                          color="transparent"
                          class="pa-0"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar size="32">
                            <v-img src="@/assets/avatar.png" />
                          </v-avatar>
                          <div v-if="!collapse" class="mx-2 light--text text-capitalize">
                            {{ (profile.fullname || '').split(' ')[0] }}
                          </div>
                          <v-icon v-if="!collapse" color="light">{{ icons.mdiMenuDown }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item to="/profil">
                          <v-list-item-title>Profil & KYC</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="handleLogout()">
                          <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-app-bar>
        </v-col>
        <v-col cols="12" class="pa-4" id="content-wrapper">
          <router-view v-if="view" :key="$route.fullPath" />
        </v-col>
      </v-row>
    </v-col>
    <m-alert />
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import mAlert from '../global/m-alert.vue';
import MNotification from '../global/m-notification.vue';
import MSidenav from '../global/m-sidenav.vue';
import {
  // MICallCalling,
  MIDocumentUpload,
  MIDirectboxReceive,
  MIEdit,
  MIDirectboxSend,
  MINotification,
  // MIFolder,
  MILogout,
  MIPenAdd,
  MITrash,
  MISecurityUser,
  MIBuildings,
  MIDocumentText,
} from '@/components/icons';
import {
  mdiBookPlusMultiple,
  mdiMenu,
  mdiMagnify,
  mdiLightbulbOutline,
  mdiMenuDown,
} from '@mdi/js';

export default {
  components: {
    mAlert,
    MSidenav,
    MNotification,
    MILogout,
    MINotification,
    // MICallCalling,
    MISecurityUser,
    MIBuildings,
    MIDocumentText,
  },
  data: () => ({
    nav: 0,
    menuDrawer: null,
    notificationDrawer: null,
    view: true,
  }),
  computed: {
    icons() {
      return { mdiBookPlusMultiple, mdiMenu, mdiMagnify, mdiLightbulbOutline, mdiMenuDown };
    },
    ...mapState('userStore', { profile: (state) => state.profile || {} }),
    logo() {
      return this.$store.getters.logo;
    },
    loading() {
      return this.$store.getters.isLoading;
    },
    collapse() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    menus: function () {
      return [
        {
          icon: MIPenAdd,
          text: 'Dokumen',
          children: [
            {
              to: '/dokumen',
              icon: MIDocumentUpload,
              text: 'Unggah Dokumen',
            },
            {
              to: '/dokumen-masuk',
              icon: MIDirectboxReceive,
              text: 'Dokumen Masuk',
            },
            {
              to: '/dokumen-keluar',
              icon: MIDirectboxSend,
              text: 'Dokumen Keluar',
            },
            {
              to: '/dokumen-draft',
              icon: MIEdit,
              text: 'Draft',
            },
            {
              to: '/sampah',
              icon: MITrash,
              text: 'Sampah',
            },
          ],
        },
      ];
    },
    bottoMenus: function () {
      return [];
    },
  },
  mounted() {},
  methods: {
    isActive(item) {
      return this.$route.path == item.to;
    },
    reloadView() {
      this.view = false;
      setTimeout(() => {
        this.view = true;
      }, 100);
    },
    handleShowNotification() {
      this.$store.dispatch('notificationStore/getList');
      this.notificationDrawer = true;
    },
    async handleLogout() {
      await this.$store.dispatch('userStore/logout');
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
#layout {
  position: relative;
}
#topnav {
  z-index: 99;
  max-width: calc(100vw - 256px);
  position: fixed;
  padding: 0;
  margin: 0;
  border-bottom: thin outset;
}
.collapse #topnav {
  max-width: 100vw;
}
#nav-icon {
  max-width: 256px;
  overflow: hidden;
  display: inline-block;
}
.collapse #nav-icon {
  min-width: unset;
}
#sidebar {
  display: flex;
  flex-direction: column;
  width: 256px;
  position: fixed;
  height: calc(100vh);
  overflow: auto;
  padding: 20px;
}
#nav-content {
  width: 100%;
  max-width: 100%;
}
#content {
  margin-left: 256px;
}
#content-wrapper {
  margin-top: 64px;
  min-height: calc(100vh - 64px);
  border-left: thin outset;
}
.collapse #content {
  margin-left: 0;
}
.collapse #content-wrapper {
  border-left: none;
}
</style>
