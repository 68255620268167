import axios from 'axios';

const api = {
  auto_login: '/v1/auth/auto-login',
  change_password: '/v1/auth/user_change_password',
  check_domain: '/v1/auth/check_subdomain',
  check_reset: '/v1/auth/check_reset_password',
  check_token: '/v1/auth/users/check_token',
  forget_password: '/v1/auth/forgot-password',
  kyc_id_card: '/v1/auth/users/kyc/idcard',
  kyc_profile: '/v1/auth/users/profile',
  kyc_video: '/v1/auth/users/kyc/video',
  login: '/v1/auth/login',
  profile: '/v1/auth/users/profile',
  refresh: '/v1/auth/users/refresh_token',
  register: '/v1/auth/register',
  resend_email: '/v1/auth/dashboard/email_verification',
  reset_password: '/v1/auth/reset-password',
  status_kyc: '/v1/auth/users/new_kyc_status',
  update_password: '/v1/auth/update_password',
  update_profile: '/v1/auth/update_profile_user',
};

const SET_AUTH = 'SET_AUTH';
const RESET_AUTH = 'RESET_AUTH';

const state = () => ({ auth: {}, profile: {}, kyc: {} });
const getters = {
  isLoggedIn(state) {
    return !!state.auth?.token;
  },
};
const mutations = {
  SET_AUTH(state, data) {
    const auth = JSON.parse(localStorage.getItem(SET_AUTH)) || {};
    if (!!data === true) {
      Object.assign(auth, data);
    }
    axios.defaults.headers.common['x-access-token'] = auth.token;

    localStorage.setItem(SET_AUTH, JSON.stringify(auth));
    state.auth = auth;
  },
  RESET_AUTH(state) {
    localStorage.removeItem(SET_AUTH);
    delete axios.defaults.headers.common['x-access-token'];
    state.auth = {};
  },
  setProfile(state, data) {
    state.profile = data;
  },
  setKycStatus(state, data) {
    state.kyc = data;
  },
};
const actions = {
  async login({ dispatch, commit }, payload) {
    const authData = {};
    const response = await dispatch('post', { url: api.login, data: payload }, { root: true });
    if (response) {
      axios.defaults.headers.common['x-access-token'] = response.data.token;
      const { data } = await dispatch('profile');
      if (!data) {
        return false;
      }
      const profile = data.profile || {};
      Object.assign(authData, { ...response.data, profile });
      await dispatch('statusKyc');
    }
    commit(SET_AUTH, authData);
    return response;
  },
  async autoLogin({ dispatch, commit }, { verification_code }) {
    const authData = {};
    const response = await dispatch(
      'post',
      { url: api.auto_login, data: { verification_code } },
      { root: true }
    );
    if (response) {
      axios.defaults.headers.common['x-access-token'] = response.data.token;
      const { data } = await dispatch('profile');
      if (!data) {
        return false;
      }
      const profile = data.profile || {};
      Object.assign(authData, { ...response.data, profile });
      await dispatch('statusKyc');
    }
    commit(SET_AUTH, authData);
    return response;
  },
  async logout({ commit }) {
    commit(RESET_AUTH);
  },
  async checkReset({ dispatch }) {
    return await dispatch('get', { url: api.check_reset }, { root: true });
  },
  async changePassword({ dispatch }, { old_password, new_password, new_password_confirm }) {
    const res = await dispatch(
      'post',
      { url: api.change_password, data: { old_password, new_password, new_password_confirm } },
      { root: true }
    );
    return res;
  },
  async updatePassword({ dispatch }, { password, password_confirm }) {
    const res = await dispatch(
      'post',
      { url: api.update_password, data: { password, password_confirm } },
      { root: true }
    );
    return res;
  },
  async forgetPassword({ dispatch }, { email }) {
    return await dispatch('post', { url: api.forget_password, data: { email } }, { root: true });
  },
  async resetPassword({ dispatch }, { token, password, password_confirm }) {
    return await dispatch(
      'post',
      { url: api.reset_password, data: { token, password, password_confirm } },
      { root: true }
    );
  },
  async register({ dispatch }, payload) {
    return await dispatch('post', { url: api.register, data: payload }, { root: true });
  },
  async profile({ dispatch, commit }) {
    const res = await dispatch('get', { url: api.profile }, { root: true });
    commit('setProfile', res.data);
    return res;
  },
  async kycKtp(context, payload) {
    return await context.dispatch('post', { url: api.kyc_id_card, data: payload }, { root: true });
  },
  async setProfile(context, payload) {
    return await context.dispatch(
      'post',
      { url: api.update_profile, data: payload },
      { root: true }
    );
  },
  async updateProfile(context, payload) {
    return await context.dispatch(
      'post',
      { url: api.update_profile, data: payload },
      { root: true }
    );
  },
  async kycVideo(context, payload) {
    return await context.dispatch('post', { url: api.kyc_video, data: payload }, { root: true });
  },
  async statusKyc({ dispatch, commit }) {
    const res = await dispatch('get', { url: api.status_kyc }, { root: true });
    commit('setKycStatus', res.data[0]);
    return res;
  },
  async resendEmail({ dispatch, rootState }) {
    const email = rootState.userStore?.auth?.account?.email;
    return await dispatch('post', { url: api.resend_email, data: { email } }, { root: true });
  },
};

export default { namespaced: true, state, getters, mutations, actions };
