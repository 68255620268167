const api = {
  list: '/v1/econtract/web_app/document/data',
  out: '/v1/econtract/web_app/document/out',
  draft: '/v1/econtract/web_app/document/list',
  trash: '/v1/econtract/web_app/document/trash',
  detail: (id) => `/v1/econtract/web_app/document/detail/${id}`,
  set_expired: '/v1/econtract/web_app/document/update_expired',
  audit_trail: (id) => `/v1/econtract/document/activities?id=${id}`,
  delete: '/v1/econtract/web_app/document/delete_docs',
  restore: '/v1/econtract/web_app/document/restore_docs',
  destroy: '/v1/econtract/web_app/document/permanent_delete',
  detail_guest: (code) => `/v1/econtract/guest/detail_document/${code}`,
};

const state = () => ({ pagination: {}, data: [], detail: {}, audit_trail: {} });
const getters = {};
const mutations = {
  resetData(state) {
    state.data = [];
    state.pagination = {};
    state.detail = {};
  },
  setPagination(state, data) {
    state.pagination = data;
  },
  setData(state, data) {
    state.data = data;
  },
  setDetail(state, data) {
    state.detail = data;
  },
  setAuditTrail(state, data) {
    state.audit_trail = data;
  },
  resetAuditTrail(state) {
    state.audit_trail = {};
  },
};
const actions = {
  async getList({ dispatch, commit }, params = {}) {
    commit('resetData');
    const response = await dispatch('get', { url: api.list, params }, { root: true });
    if (response) {
      commit('setPagination', response.data.pagination);
      commit('setData', response.data.documents);
    }
    return response;
  },
  async getDraft({ dispatch, commit }, params = {}) {
    commit('resetData');
    const response = await dispatch('get', { url: api.draft, params }, { root: true });
    if (response) {
      commit('setPagination', response.data.pagination);
      commit('setData', response.data.documents.docs);
    }
    return response;
  },
  async getOut({ dispatch, commit }, params = {}) {
    commit('resetData');
    const response = await dispatch('get', { url: api.out, params }, { root: true });
    if (response) {
      commit('setPagination', response.data.pagination);
      commit('setData', response.data.documents);
    }
    return response;
  },
  async getTrash({ dispatch, commit }, params = {}) {
    commit('resetData');
    const response = await dispatch('get', { url: api.trash, params }, { root: true });
    if (response) {
      commit('setPagination', response.data.pagination);
      commit('setData', response.data.documents);
    }
    return response;
  },
  async getDetail({ dispatch, commit }, id) {
    commit('resetData');
    const response = await dispatch('get', { url: api.detail(id) }, { root: true });
    if (response) {
      commit('setDetail', response.data);
    }
    return response;
  },
  async setDeadline({ dispatch }, { id, expired, reminder }) {
    const response = await dispatch(
      'post',
      {
        url: api.set_expired,
        data: { id, expired, reminder },
      },
      { root: true }
    );
    return response;
  },
  async getAuditTrail({ dispatch, commit }, { id }) {
    commit('resetAuditTrail');
    const response = await dispatch('get', { url: api.audit_trail(id) }, { root: true });
    commit('setAuditTrail', response.data);
    return response;
  },
  async deleteDoc({ dispatch }, { id }) {
    const res = await dispatch(
      'post',
      { url: api.delete, data: { document_id: id } },
      { root: true }
    );
    return res;
  },
  async getDetailGuest({ dispatch, commit }, code) {
    commit('resetData');
    const response = await dispatch('get', { url: api.detail_guest(code) }, { root: true });
    if (response) {
      commit('setDetail', response.data);
    }
    return response;
  },
  async restoreDoc({ dispatch }, { id }) {
    const res = await dispatch(
      'post',
      { url: api.restore, data: { document_id: id } },
      { root: true }
    );
    return res;
  },
  async destroyDoc({ dispatch }, { id }) {
    const res = await dispatch(
      'post',
      { url: api.destroy, data: { document_id: id } },
      { root: true }
    );
    return res;
  },
};

export default { namespaced: true, state, getters, mutations, actions };
