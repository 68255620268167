// import view from '../views';

const api = {
  register: '/v1/auth/register',
  login: '/v1/auth/login',
  refresh: '/v1/auth/users/refresh_token',
  profile: '/v1/auth/users/profile',
  check_token: '/v1/auth/users/check_token',
  check_domain: '/v1/auth/check_subdomain',
  kyc_id_card: '/v1/auth/users/kyc/idcard',
  update_profile: '/v1/auth/users/profile',
  kyc_video: '/v1/auth/users/kyc/video',
  status_kyc: '/v1/auth/users/new_kyc_status',
  resend_email: '/v1/auth/dashboard/email_verification',
};

const web = [];

export { api };

export default web;
