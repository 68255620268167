<template>
  <v-navigation-drawer
    color="white"
    class="mt-16"
    fixed
    right
    hide-overlay
    v-model="model"
    temporary
    width="400"
    style="height: calc(100vh - 64px)"
  >
    <v-row no-gutters class="mb-5 px-5 pt-4">
      <v-spacer />
      <v-btn icon small @click="model = false">
        <v-icon small>{{ icons.mdiCloseCircle }}</v-icon></v-btn
      >
    </v-row>
    <v-row
      no-gutters
      class="flex-wrap flex-md-nowrap px-5 pt-4"
      justify="space-between"
      align="center"
    >
      <span class="text-h6 mb-4">Notification</span>
      <span class="mb-4">
        <v-select
          v-model="sortBy"
          :items="sortByItems"
          dense
          outlined
          style="width: 150px"
          label="Urutkan"
        ></v-select>
      </span>
    </v-row>
    <v-progress-linear v-if="loading" indeterminate />
    <v-list
      class="px-5 pt-4 overflow-y-auto"
      v-else
      nav
      two-line
      style="max-height: calc(100% - 136px)"
    >
      <v-list-item
        class="px-4"
        v-for="(item, index) in items"
        :key="`notification-${index}`"
        to="#"
        :style="{ backgroundColor: '#F2FAFF' }"
      >
        <v-list-item-icon class="mr-3"><v-icon>$iDocumentActive</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="mb-1">{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap mb-1">
            {{ item.description }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="text-wrap text-caption">{{
            new Date(item.createdAt).toLocaleString()
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-show="false">
          <v-icon class="my-auto" color="primary">{{ icons.mdiChevronRight }}</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-intersect="loadMore">
        <v-list-item-title>Memuat lebih banyak</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { mdiChevronRight, mdiCloseCircle } from '@mdi/js';

export default {
  model: { prop: 'modelData', event: 'change' },
  props: {
    modelData: { type: [Boolean, String, Number, Object, File] },
    loading: Boolean,
  },
  data: () => ({
    sortBy: 'latest',
    visibled: 10,
  }),
  computed: {
    icons() {
      return { mdiChevronRight, mdiCloseCircle };
    },
    ...mapState('notificationStore', { itemData: (state) => state.data }),
    items() {
      let data = [...this.itemData];
      data = data.sort((a, b) => {
        const date1 = new Date(a.createdAt);
        const date2 = new Date(b.createdAt);
        if (this.sortBy == 'latest') {
          return date2 - date1;
        } else {
          return date1 - date2;
        }
      });
      return data.slice(0, this.visibled);
    },
    sortByItems() {
      return [
        { text: 'Paling baru', value: 'latest' },
        { text: 'Paling lama', value: 'oldest' },
      ];
    },
    model: {
      get() {
        return this.modelData;
      },
      set(val) {
        if (!val) this.visibled = 10;
        this.$emit('change', val);
      },
    },
  },
  methods: {
    loadMore() {
      setTimeout(() => {
        this.visibled += 10;
      }, 1000);
    },
  },
};
</script>
